$(document).ready(function () {
    
    $('.owl-carousel').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 10,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 5
            },
            1000: {
                items: 8
            }
        }
    });
 

    $("html").on("click", "a.ajax", function (e) {
        $(this).ajaxUrlLoad();
        e.preventDefault();
    });
    $("html").on("submit", "form.ajax", function (e) {
        $(this).ajaxFormSubmit();
        e.preventDefault();
    });
    $("html").on("click", "form.ajax a.submit", function (e) {
        $(this).parent("form").submit();
        e.preventDefault();
    });
    
});


$(document).ready(function () {




    /*==================================
     Parallax Effect for Home page
     ====================================*/

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    } else {



        // Tiny jQuery Plugin
        // by Chris Goodchild
        $.fn.exists = function (callback) {
            var args = [].slice.call(arguments, 1);

            if (this.length) {
                callback.call(this, args);
            }

            return this;
        };

        // Usage Parallax 
        $('.parallax-image-1').exists(function () {

            //alert('1 here');
            var offsetParallax1 = $(".parallax-image-1").offset().top;
            $('.parallax-image-1').parallax("50%", offsetParallax1, 0.1, true);

        });

        $('.parallax-image-2').exists(function () {

            //alert('2 here');
            var offsetParallax2 = $(".parallax-image-2").offset().top;
            $('.parallax-image-2').parallax("50%", offsetParallax2, 0.1, true);

        });


    } // mobile userAgent end 


    /*==================================
     Home Page Slider  || jQuery Cycle
     ====================================*/


    $('.slider-v1').cycle({
        //Specify options
        fx: 'scrollHorz',
        //Name of transition effect 
        slides: '.slider-item',
        timeout: 5000,
        // set time for nex slide 
        speed: 1200,
        easeIn: 'easeInOutExpo',
        // easing 
        easeOut: 'easeInOutExpo',
        pager: '#pager2',
        //Selector for element to use as pager container 
    });


    $('.slider-v2').cycle({
        //Specify options
        fx: 'scrollHorz',
        //Name of transition effect 
        slides: '.slider-item',
        timeout: 5000,
        // set time for nex slide 
        speed: 1200,
        easeIn: 'easeInOutExpo',
        // easing 
        easeOut: 'easeInOutExpo',
        pager: '#pager',
        //Selector for element to use as pager container 
    });


    // show loading image
    $('#loader_img').show();

    // main image loaded 
    $('.sliderImg').load(function () {
        // hide/remove the loading image
        $('#loader_img').hide();
    });


}); // end Ready

function animateProductImageToCart(data) {

    var navbar = $('.navbar-right');
    var cart = $('.cartMenu');
    var imgtodrag = $(".product-" + data.id).find("img").eq(0);

    if (imgtodrag) {
        var imgclone = imgtodrag.clone().css("z-index", "1051")
                .offset({
                    top: imgtodrag.offset().top,
                    left: imgtodrag.offset().left
                }).css({
            'opacity': '0.5',
            'position': 'absolute',
            'height': '150px',
            'width': '150px',
            'z-index': '1051'
        })
                .appendTo($('body'))
                .animate({
                    'top': navbar.offset().top + 27,
                    'left': navbar.offset().left + 27,
                    'width': 75,
                    'height': 75
                }, 1000, 'easeInOutExpo');


        imgclone.animate({
            'width': 0,
            'height': 0
        }, function () {
            $(this).detach();
        });
    }
}
;

function openModal(body, title, options) {
    //show close button
    $("#modalWrapper button.close").show();

    //process Title
    if (isset(title) && title.length > 0) {			//if title is provided => show it
        $("#modalWrapper #modalTitle").html(title);
        $(".modal-header").removeClass("hidden");
    } else {										//otherwise hide header container
        $(".modal-header").addClass("hidden");
    }

    if (isset(options) && isset(options.fullWidth) && options.fullWidth == true) {
        $(".modal-lg").addClass("modal-fullWidth");
    } else {
        $(".modal-lg").removeClass("modal-fullWidth");
    }

    //process close modal
    var backdropOption = true;
    var keyboardOption = true;
    if (isset(options)) {
        if (isset(options.close) && options.close === false) {
            backdropOption = 'static';
            keyboardOption = false;

            $("#modalWrapper button.close").hide();
        }
    }

    //add body content
    $("#modalWrapper .modal-body").html(body);

    //show modal
    return $('#modalWrapper').modal({
        show: true,
        backdrop: backdropOption,
        keyboard: keyboardOption
    });
}

function renderHeaderCart(data) {
    if(isset(data.cartTotalValue)) {
        $(".placeholderTotalPrice").text(data.cartTotalValue);
    }
    if(isset(data.cartTotalValueVat)) {
        $(".placeholderTotalPriceTax").text(data.cartTotalValueVat);
    }
    
    if(isset(data.cartContent)) {
        $(".cartItemsWrapper").html(data.cartContent);
    }
    
}

function removeItemFromHeaderCart(data) {
    
    if(isset(data.cartTotalValue)) {
        $(".placeholderTotalPrice").text(data.cartTotalValue);
    }
    if(isset(data.cartTotalValueVat)) {
        $(".placeholderTotalPriceTax").text(data.cartTotalValueVat);
    }
    
    $(".item-"+data.rowID).fadeOut(function() {
        $(this).remove();
    });
}

function updateCartInfoBox(data) {
    if(isset(data.cartWeight)) {
        $(".placeholderTotalWeight").text(data.cartWeight);
    }
    if(isset(data.cartTotalValue)) {
        $(".placeholderTotalPrice").text(data.cartTotalValue);
    }
    if(isset(data.cartTotalValue)) {
        $(".placeholderTotalPriceTax").text(data.cartTotalValueVat);
    }
    if(isset(data.cartTotalVat)) {
        $(".placeholderTotalTax").text(data.cartTotalVat);
    }
}

function updateCartItem(data) {
    $("#cartItem-"+data.rowID+" .productPrice").text(data.productPrice);
    $("#cartItem-"+data.rowID+" .productPriceVat").text(data.productPriceVat);
}


function removeItemFromCart(data) {
    $("#cart-summary #price").text(data.cartTotalValue);
    $("#cart-summary #total-tax").text(data.cartTotalVat);
    $("#cart-summary #total-price-vat").text(data.cartTotalValueVat);
    $("#cart-summary #total-weight").text(data.cartWeight);
    
    $("#cartItem-"+data.rowID).fadeOut(function() {
        $(this).remove();
    });
}
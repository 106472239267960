(function ($) {
    $.fn.ajaxUrlLoad = function (options) {
        var loadHandler = function ( ) {
            var confirmationTitle = (isset(options) && isset(options.confirmationTitle)) ? options.confirmationTitle : $(this).data("confirm"),
                confirmationMessage = (isset(options) && isset(options.confirmationMessage)) ? options.confirmationMessage : $(this).data("confirm-message"),  
                that = $(this);
        
            if (isset(confirmationTitle) && isset(confirmationMessage)) {
                bootbox.dialog({
                    message: confirmationMessage,
                    title: confirmationTitle,
                    buttons: {
                        success: {
                            label: "Nie",
                            className: "btn-success"
                        },
                        danger: {
                            label: "Áno",
                            className: "btn-danger",
                            callback: function () {
                                load(that, options);
                            }
                        }
                    }
                });
                return false;
            } else {
                if(isset(confirmationTitle)) {
                    bootbox.confirm(confirmationTitle, function (result) {
                        if (result) {
                            load(that, options);
                        }
                    }); 
                } else {
                    load(that, options);
                }
                return false;
            }
            
            if (options && options.target === "return") {
                var result = load(that, options);
                return result;
            } else {
                load(that, options);
            }
        }
        if ((this).length !== 0) {
            return this.each(loadHandler);
        } else {
            return loadHandler();
        }
    };
})(jQuery);

(function ($) {
    $.fn.ajaxFormSubmit = function (options) {
        var submitHandler = function () {
            var confirmationTitle = (isset(options) && isset(options.confirmationTitle)) ? options.confirmationTitle : $(this).data("confirm"),
                confirmationMessage = (isset(options) && isset(options.confirmationMessage)) ? options.confirmationMessage : $(this).data("confirm-message"),                
                that = $(this);
        
            if (isset(confirmationTitle) && isset(confirmationMessage)) {
                bootbox.dialog({
                    message: confirmationMessage,
                    title: confirmationTitle,
                    buttons: {
                        success: {
                            label: "Nie",
                            className: "btn-success"
                        },
                        danger: {
                            label: "Áno",
                            className: "btn-danger",
                            callback: function () {
                                submit(that, options);
                            }
                        }
                    }
                });
                return false;
            } else {
                if(isset(confirmationTitle)) {
                    bootbox.confirm(confirmationTitle, function (result) {
                        if (result) {
                            submit(that, options);
                        }
                    }); 
                } else {
                    submit(that, options);
                }
                return false;
            }
            
            if (options && options.target === "return") {
                return submit(that, options);
            } else {
                submit(that, options);
            }
        }
        if ((this).length != 0) {
            return this.each(submitHandler);
        } else {
            return submitHandler();
        }
    }
})(jQuery);
function load(that, options) {
    var url = (isset(options) && isset(options.href)) ? options.href : that.attr("href"),
        target = (isset(options) && isset(options.target)) ? options.target : that.attr("data-target"),
        callback = (isset(options) && isset(options.callback)) ? options.callback : that.attr("data-callback"),
        method = (isset(options) && isset(options.method)) ? options.method : that.attr("data-method");
        
    var returnData = "";

    $.ajax({
        url: url,
        type: (isset(method)) ? method : "get",
        dataType: 'json',
        beforeSend: function (xhr) {
            var token = $('meta[name="csrf_token"]').attr('content');
            if (token) {
                return xhr.setRequestHeader('X-XSRF-TOKEN', token);
            }
        },
        async: (target === "return") ? false : true,
        success: function (data) {
            if(isset(data.notifications)) {
                buildNotifications(data.notifications);
            }
            if(isset(data.swalNotifications)) {
                buildSwalNotifications(data.swalNotifications);
            }
            if (isset(data.target)) {
                target = data.target;
            }
            if (isset(callback)) {
                callMethod(callback, data);
            }
            if (isset(data.closePopUp) && data.closePopUp) {
                $("button.close").trigger('click');
            }
            if (isset(data.callback)) {
                callMethod(data.callback, data);
            }
            
            if (target === "return") {
                returnData = data;
            } else if (target === "popup" || (isset(data.target) && data.target === "popup")) {
                openModal(data.html, data.title);
            } else {
                $(target).html(data.html).show();
            }
        },
        error: function (xhr) {
            if(xhr.status === 401) {
                jsRedirect({
                    url: "/admin"
                });
            }
            if(xhr.status === 422) {
                $.each(xhr.responseJSON, function(item, value) {
                    buildNotifications([
                        {type: 'warning',message: value}
                    ]);
                });
            }
        }
    });
    
    if (target === "return") {
        return returnData;
    }
}
function submit(that, options) {
    var url = (isset(options) && isset(options.url)) ? options.url : that.attr("action"),
        target = (isset(options) && isset(options.target)) ? options.target : that.attr("data-target"),
        callback = (isset(options) && isset(options.callback)) ? options.callback : that.attr("data-callback"),
        formData = (isset(options) && isset(options.formData)) ? options.formData : new FormData(that[0]),
        method = (isset(options) && isset(options.method)) ? options.method : that.attr("data-method"),
        returnData = "";

    if(isset(method) && method === "") {
        method = "POST";
    } else {
        if(!isset(method)) {
            method = "POST";
        }
    }

    //Check if formData is generic JS object, if yes -> transform it into FormData
    if(formData.constructor.name === "Object") {
        var objectData = formData;
        
        delete formData;
        var formData   = new FormData();

        $.each(objectData, function(index, value) {
            formData.append(index, value) 
        });
    }
    
    //Hack it, because of Laravel
    if(method === 'PATCH') {
        formData.append('_method', 'PATCH');
        method = "POST";
    }
    
    $.ajax({
        url: url,
        beforeSend: function (xhr) {
            var token = $('meta[name="csrf_token"]').attr('content');
            if (token) {
                return xhr.setRequestHeader('X-XSRF-TOKEN', token);
            }
        },
        type: method,
        data: formData,
        processData: false,
        contentType: false,
        dataType: 'json',
        async: (target === "return") ? false : true,
        success: function (data) {
            if(isset(data)) {
                
                if(isset(data.notifications)) {
                    buildNotifications(data.notifications);
                }
                if(isset(data.swalNotifications)) {
                    buildSwalNotifications(data.swalNotifications);
                }
                if (isset(data.target)) {
                    target = data.target;
                }
                if (isset(callback)) {
                    callMethod(callback, data);
                }
                if (isset(data.callback)) {
                    callMethod(data.callback, data);
                }
                if (isset(data.closePopUp) && data.closePopUp) {
                    $("button.close").trigger('click');
                }
                if (target === "return") {
                    returnData = data;
                } else if (target === "popup" || (isset(data.target) && data.target === "popup")) {
                    openModal(data.html, data.title);
                }  else if (isset(data.target)) {
                    $(data.target).html(data.html).show();
                } else {
                    $(target).html(data.html).show();
                }
            }
        },
        error: function (xhr) {
            if(xhr.status === 401) {
                jsRedirect({
                    url: "/admin"
                });
            }
            //Validation error
            if(xhr.status === 422) {
                $.each(xhr.responseJSON, function(item, value) {
                    buildNotifications([
                        {type: 'warning',message: value}
                    ]);
                });
                buildNotifications([
                        {type: 'error',message: 'Odoslanie zlyhalo z nasledujúcich dôvodov:'}
                    ]);
            } else {
                buildNotifications([{
                    type: 'error',
                    title: 'Error: ' + xhr.status,
                    message:  xhr.statusText,
                }]);
            }
        }
    });
    
    if (target === "return") {
        return returnData;
    }
}


function isset(variable) {
    if (variable !== null && variable !== undefined && typeof variable !== "undefined") {
        return true;
    }
    return false;
}

function buildNotifications(notifications) {
    $.each(notifications, function(i, notification) {
        if(notification.type === 'success') {
            toastr.success(notification.message, notification.title, {timeOut: 10000});
        } else {
            if(notification.type === 'info') {
                toastr.info(notification.message, notification.title, {timeOut: 10000});
            } else {
                if(notification.type === 'warning') {
                    toastr.warning(notification.message, notification.title, {timeOut: 15000});
                } else {
                    toastr.error(notification.message, notification.title, {timeOut: 15000});
                }
            }
        }
    });
}

function buildSwalNotifications(notifications) {
    $.each(notifications, function(i, notification) {
        var options = {
            title: notification.title,
            text: notification.message,
            type: notification.type,
            closeOnConfirm: true,   
            closeOnCancel: true,
            confirmButtonText: "OK",
            confirmButtonClass: "btn-info",
            cancelButtonClass: "btn-danger",
            html: true
        }
        
        var confirmFunction = function(){ };
        var cancelFunction = function(){ };
        
        if(isset(notification.options)) {
            
            if(isset(notification.options.confirmButton)) {
                if(isset(notification.options.confirmButton.text) && notification.options.confirmButton.text) {
                    options.confirmButtonText = notification.options.confirmButton.text;
                }
                
                if(isset(notification.options.confirmButton.cssClass) && notification.options.confirmButton.cssClass) {
                    options.confirmButtonClass = notification.options.confirmButton.cssClass;
                }
                
                if(isset(notification.options.confirmButton.url) && notification.options.confirmButton.url) {
                    confirmFunction = function(){ 
                        jsRedirect({
                            url: notification.options.confirmButton.url
                        });
                    };
                }
                
                if(isset(notification.options.confirmButton.ajaxUrl) && notification.options.confirmButton.ajaxUrl) {
                    confirmFunction = function(){ 
                        $.fn.ajaxUrlLoad({
                            href: notification.options.confirmButton.ajaxUrl
                        });
                    };
                }
                
            }
            
            if(isset(notification.options.cancelButton)) {
                if(isset(notification.options.cancelButton.text) && notification.options.cancelButton.text) {
                    options.showCancelButton = true;
                    options.cancelButtonText = notification.options.cancelButton.text;
                }
                
                if(isset(notification.options.cancelButton.cssClass) && notification.options.cancelButton.cssClass) {
                    options.cancelButtonClass = notification.options.cancelButton.cssClass;
                }
                
                if(isset(notification.options.cancelButton.close)) {
                    options.closeOnCancel = notification.options.cancelButton.close;
                }
                
                if(isset(notification.options.cancelButton.url) && notification.options.cancelButton.url) {
                    cancelFunction = function(){ 
                        jsRedirect({
                            url: notification.options.cancelButton.url
                        });
                    };
                }
                
                if(isset(notification.options.cancelButton.ajaxUrl) && notification.options.cancelButton.ajaxUrl) {
                    confirmFunction = function(){ 
                        $.fn.ajaxUrlLoad({
                            href: notification.options.confirmButton.ajaxUrl
                        });
                    };
                }
            }
            
            
        }
        
        swal(options, function(isConfirm) {
            if(isConfirm) {
                confirmFunction();
            } else {
                cancelFunction();
            }
        });
    });
}

function callMethod(methods, that) {
    var split = methods.split(",");
    var method;
    
    split.forEach(function(methodName){
        try {
            if(methodName.length) {
                method = eval('(' + methodName + ')');
                method(that);
            }
        } catch (err) {
            //console.log(err);
        }
    });
}

function jsRedirect(data) {
    window.location.href = data.url;
}


function openModal(body, title, options) {
	//show close button
	$("#modalWrapper button.close").show();
	
	//process Title
	if(isset(title) && title.length > 0) {			//if title is provided => show it
		$("#modalWrapper #modalTitle").html(title);
		$(".modal-header").removeClass("hidden");
	} else {										//otherwise hide header container
		$(".modal-header").addClass("hidden");
	}
	
	if(isset(options) && isset(options.fullWidth) && options.fullWidth === true) {
		$(".modal-lg").addClass("modal-fullWidth");
	} else {
		$(".modal-lg").removeClass("modal-fullWidth");
	}
	
	//process close modal
	var backdropOption = true;
	var keyboardOption = true;
	if(isset(options)) {
		if(isset(options.close) && options.close === false) {
			backdropOption = 'static';
			keyboardOption = false;

			$("#modalWrapper button.close").hide();
		}
	}
	
	//add body content
	$("#modalWrapper .modal-body").html(body);
	
	//show modal
	return $('#modalWrapper').modal({
			show: true,
			backdrop: backdropOption,
			keyboard: keyboardOption
		});
}

function closeModal() {
    $('#modalWrapper').modal('hide');
}

function reload() {
    location.reload();
}

function fadeOutTableRow(data) {
    if(isset(data.tableRowID)) {
        $(data.tableRowID).fadeOut();
    }
}

$(document).ready(function(){
    $('html').on('click', 'a.ajax,button.ajax', function (e) {
        e.preventDefault();
        $(this).ajaxUrlLoad();
    });
    $('html').on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        $(this).ajaxFormSubmit();
    });
});